import React, { useEffect, useState } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PageTemplate = () => {
  return (
    <Layout>
      <SEO
        title="Quem Somos"
        description="Em nome de Allah, O Clemente, O Misericordioso Fundado em 2014, o Iqara Islam nasceu como um portal com o objetivo de difundir o conhecimento sobre a religião Islâmica aos falantes da língua portuguesa, abordando temas diversificados, que atendam a demanda do público por conhecimento, procurando sempre fornecer conteúdos únicos e soluções para o dia"
        url={`${process.env.GATSBY_HOST_URL}/quem-somos`}
      />

      <div
        class="content-grid page-grid contato"
        style={{ maxWidth: "1100px", margin: "0 auto" }}
      >
        <div class="content content-custom">
          <h1 itemprop="name" itemtype="http://schema.org/Thing">
            Contato
          </h1>
          <div>
            <p>
              Email: <strong>contato@iqaraislam.com</strong>
            </p>

            <h2>Endereço</h2>

            <p>Rua Marechal Floriano, 654, Sala 607, Centro</p>

            <p>Governador Valadares &#8211; Minas Gerais, Brasil</p>

            <p>CEP 35010140</p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PageTemplate
